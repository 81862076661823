import styles from '@/styles/Main/Home/Home.module.scss';
import { ModalContext } from '@/utils/ModalContext';
import { useSession } from 'next-auth/react';
import { useRouter } from 'next/router';
import { ReactNode, useContext, useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import DialogModals from '../Modals/DialogModals';
import useCategoryStore from '../Pages/Profile/categoryStore';
interface LayoutProps {
  children: ReactNode;
}

const AuthorizedLayout = ({ children }: LayoutProps) => {
  const { data, status } = useSession();
  const router = useRouter();

  const { fetchCategoryOption } = useCategoryStore();
  useEffect(() => {
    if (status === 'unauthenticated') {
      router.push('/login');
    }
  }, [router, status]);
  const { openModal } = useContext(ModalContext);
  useEffect(() => {
    if (!isMobile) {
      openModal(
        <DialogModals
          action={async () => {
            router.push('/');
          }}
          title="Info!"
          type="info"
          label="Go HomePage"
          message={`Login dashboard is currently only available on mobile browsers. Please visit from a mobile device for the best experience`}
        />
      );
    }

    return () => {};
  }, [isMobile, router]);
  useEffect(() => {
    fetchCategoryOption();
  }, [fetchCategoryOption]);
  return (
    <div
      className={styles.mainHomecontainer}
      style={{
        backgroundColor: '#1C1C1E',
      }}
    >
      {/* <AuthMainHeader /> */}
      <div>{children}</div>
      {/* <AuthMainFooter /> */}
      {/* <SupportFAB /> */}
      {/* {isMobile && data?.user.verified && <FixedBottomNavigation />} */}
    </div>
  );
};

export default AuthorizedLayout;
