import { MobileContext } from '@/utils/DetectMobileContext';
import { useContext } from 'react';

const DotLoader = () => {
  const isMobile = useContext(MobileContext);
  return (
    <div
      className="loaderMain"
      style={{
        width: '100%',
        height: '100%',
        maxHeight: '500px',
        marginBottom: '60px',
      }}
    >
      <span className={isMobile ? 'mloader' : 'loader'}></span>
    </div>
  );
};

export default DotLoader;
