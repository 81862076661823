import axios from 'axios';

const baseURL =
  process.env.NODE_ENV === 'development'
    ? 'http://0.0.0.0:4444/api/v3/'
    : 'https://admin.aajproperty.com/api/v3/';

const api = axios.create({
  baseURL,
});

export const setAuthToken = (token: string) => {
  if (token) {
    api.defaults.headers.common['token'] = token;
  } else {
    delete api.defaults.headers.common['token'];
  }
};
export default api;
