const BtLogoSvg = ({
  color = '#d12d34',
  color2 = '#A72328',
  width = 151,
  height = 34,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 151 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_302_7893)">
        <path
          d="M0.953197 0.131498C0.57185 -0.176998 0 0.0918212 0 0.579579L8.90956e-06 32.4802C8.90956e-06 33.1982 0.405972 33.7802 0.906752 33.7802H9.48009C9.88772 33.7802 10.2182 33.4526 10.2182 33.0489V19.8156C10.2182 19.3867 10.6729 19.107 11.0609 19.2971L17.0488 22.2273C17.1574 22.2805 17.2845 22.2013 17.2828 22.0813C17.2758 21.5767 17.2638 20.4536 17.2821 19.6886V13.659C17.2821 13.3463 17.1646 13.0578 16.9746 12.9041L0.953197 0.131498Z"
          fill="#A72328"
        />
        <path
          d="M20.0215 23.5784C20.0215 23.6393 20.0564 23.695 20.1115 23.722L23.4824 25.3744C23.4427 25.3548 23.4824 26.046 23.4824 26.5411V33.0313C23.4824 33.4353 23.8129 33.7626 24.2205 33.7626H30.8977C31.3984 33.7626 31.8043 33.1806 31.8043 32.4626V12.8236C31.8043 12.3013 31.161 12.0465 30.7976 12.4248L20.7313 19.1853C20.2874 19.4835 20.0215 19.9803 20.0215 20.5117V23.5784Z"
          fill="#A72328"
        />
        <mask
          id="mask0_302_7893"
          style={{ maskType: 'alpha' }}
          maskUnits="userSpaceOnUse"
          x="10"
          y="19"
          width="14"
          height="15"
        >
          <path
            d="M10.2124 19.8173V33.0644V33.9997H23.4415V25.3429L10.9164 19.2432L10.2124 19.8173Z"
            fill="black"
          />
        </mask>
        <g mask="url(#mask0_302_7893)">
          <path
            d="M11.4321 18.2879H11.4321C11.1902 18.2879 10.9941 18.4821 10.9941 18.7217V33.3284C10.9941 33.5681 11.1902 33.7623 11.4321 33.7623C11.6739 33.7623 11.87 33.5681 11.87 33.3284V18.7217C11.87 18.4821 11.6739 18.2879 11.4321 18.2879Z"
            fill="#D12D34"
          />
          <path
            d="M13.2372 18.2879H13.2372C12.9954 18.2879 12.7993 18.4821 12.7993 18.7217V33.3284C12.7993 33.5681 12.9954 33.7623 13.2372 33.7623C13.4791 33.7623 13.6752 33.5681 13.6752 33.3284V18.7217C13.6752 18.4821 13.4791 18.2879 13.2372 18.2879Z"
            fill="#D12D34"
          />
          <path
            d="M15.0424 15.6852H15.0424C14.8006 15.6852 14.6045 15.8794 14.6045 16.119V33.3289C14.6045 33.5685 14.8006 33.7628 15.0424 33.7628C15.2843 33.7628 15.4803 33.5685 15.4803 33.3289V16.119C15.4803 15.8794 15.2843 15.6852 15.0424 15.6852Z"
            fill="#D12D34"
          />
          <path
            d="M16.8476 15.6852H16.8476C16.6057 15.6852 16.4097 15.8794 16.4097 16.119V33.3289C16.4097 33.5685 16.6057 33.7628 16.8476 33.7628C17.0895 33.7628 17.2855 33.5685 17.2855 33.3289V16.119C17.2855 15.8794 17.0895 15.6852 16.8476 15.6852Z"
            fill="#D12D34"
          />
          <path
            d="M20.4584 21.4611H20.4584C20.2166 21.4611 20.0205 21.6554 20.0205 21.895V33.32C20.0205 33.5597 20.2166 33.7539 20.4584 33.7539C20.7003 33.7539 20.8964 33.5597 20.8964 33.32V21.895C20.8964 21.6554 20.7003 21.4611 20.4584 21.4611Z"
            fill="#D12D34"
          />
          <path
            d="M18.6528 21.749H18.6528C18.4109 21.749 18.2148 21.9432 18.2148 22.1828V33.3205C18.2148 33.5601 18.4109 33.7544 18.6528 33.7544C18.8946 33.7544 19.0907 33.5601 19.0907 33.3205V22.1828C19.0907 21.9432 18.8946 21.749 18.6528 21.749Z"
            fill="#D12D34"
          />
          <path
            d="M22.2631 21.4611H22.2631C22.0213 21.4611 21.8252 21.6554 21.8252 21.895V33.32C21.8252 33.5597 22.0213 33.7539 22.2631 33.7539C22.505 33.7539 22.701 33.5597 22.701 33.32V21.895C22.701 21.6554 22.505 21.4611 22.2631 21.4611Z"
            fill="#D12D34"
          />
        </g>
        <path
          d="M45.5627 22.5107H43.2188V20.7995H45.5627V16.8883H49.0173V20.7995H52.8416V22.5107H49.0173V28.8665C49.0173 29.4858 49.0831 30.0154 49.2144 30.4554C49.3626 30.8791 49.5517 31.2296 49.7819 31.5066C50.0287 31.7674 50.3086 31.9628 50.6209 32.0932C50.9335 32.2237 51.2626 32.2889 51.608 32.2889C52.1015 32.2889 52.5786 32.2156 53.0391 32.0688C53.4995 31.9059 53.8861 31.7347 54.1987 31.5554L54.939 33.1444C54.4455 33.3889 53.878 33.5926 53.2365 33.7555C52.6113 33.9184 51.9861 34 51.3612 34C49.5024 34 48.0713 33.5438 47.068 32.631C46.0644 31.7183 45.5627 30.4636 45.5627 28.8665V22.5107Z"
          fill="#D12D34"
        />
        <path
          d="M63.5653 32.2889C64.3715 32.2889 65.1199 32.1504 65.8106 31.8731C66.5182 31.5962 67.2089 31.2052 67.8835 30.6999L68.8703 32.1665C68.5577 32.4437 68.1876 32.6963 67.7599 32.9244C67.3487 33.1525 66.8963 33.3481 66.4028 33.511C65.9258 33.6578 65.4241 33.7719 64.8978 33.8532C64.3715 33.9512 63.8451 34 63.3188 34C62.1508 34 61.0816 33.8451 60.111 33.5354C59.157 33.2421 58.3427 32.8103 57.6682 32.24C56.994 31.6534 56.4677 30.9363 56.0892 30.0887C55.7273 29.2413 55.5464 28.2635 55.5464 27.1553C55.5464 26.0634 55.7192 25.0938 56.0646 24.2463C56.4265 23.3826 56.9197 22.6655 57.5449 22.0951C58.1864 21.5085 58.9433 21.0684 59.8149 20.7751C60.6866 20.4654 61.6491 20.3106 62.7017 20.3106C63.6888 20.3106 64.6182 20.4573 65.4899 20.7506C66.3619 21.044 67.1185 21.4759 67.7599 22.0462C68.4017 22.6003 68.9114 23.2766 69.2899 24.0752C69.668 24.8737 69.8574 25.7782 69.8574 26.7886V28.4998H59.3214C59.5189 29.8524 59.9878 30.8221 60.7278 31.4089C61.4847 31.9955 62.4303 32.2889 63.5653 32.2889ZM62.7017 21.8996C62.2247 21.8996 61.7808 21.9892 61.3696 22.1685C60.958 22.3477 60.5965 22.6329 60.2839 23.0241C59.9878 23.4152 59.741 23.9204 59.5435 24.5397C59.3626 25.1589 59.264 25.9086 59.2474 26.7886H66.1563C66.1563 25.9412 66.0574 25.2078 65.8602 24.5885C65.6628 23.9693 65.3994 23.4641 65.0706 23.073C64.758 22.6655 64.388 22.3722 63.9603 22.1929C63.5491 21.9973 63.1295 21.8996 62.7017 21.8996Z"
          fill="#D12D34"
        />
        <path
          d="M82.4389 22.5107H79.4782C78.3594 22.5107 77.5043 22.8122 76.9121 23.4152C76.3197 24.0019 76.0236 24.8819 76.0236 26.0552V33.511H72.5693V26.0552C72.5693 24.393 73.0957 23.1055 74.1486 22.1929C75.2178 21.264 76.7474 20.7995 78.7379 20.7995H82.4389V22.5107Z"
          fill="#D12D34"
        />
        <path
          d="M84.4028 20.7995H97.2335C99.5858 20.7995 101.321 21.2233 102.44 22.0707C103.575 22.9018 104.142 24.1485 104.142 25.8108V33.511H100.688V25.8108C100.688 24.6374 100.392 23.7981 99.7995 23.2929C99.2074 22.7714 98.3519 22.5107 97.2335 22.5107H95.9996V33.511H92.5453V22.5107H87.8574V33.511H84.4028V20.7995Z"
          fill="#D12D34"
        />
        <path
          d="M111.041 33.511H107.586V21.044H111.041V33.511ZM111.164 17.7439C111.164 18.2328 110.991 18.6646 110.646 19.0395C110.317 19.398 109.873 19.5773 109.314 19.5773C108.754 19.5773 108.302 19.398 107.956 19.0395C107.627 18.6646 107.463 18.2328 107.463 17.7439C107.463 17.255 107.627 16.8313 107.956 16.4727C108.302 16.0979 108.754 15.9105 109.314 15.9105C109.856 15.9105 110.3 16.0979 110.646 16.4727C110.991 16.8313 111.164 17.255 111.164 17.7439Z"
          fill="#D12D34"
        />
        <path
          d="M128.19 33.511H124.736V25.8108C124.736 24.6374 124.439 23.7981 123.847 23.2929C123.255 22.7714 122.4 22.5107 121.281 22.5107H118.073V33.511H114.619V20.7995H121.281C123.633 20.7995 125.369 21.2233 126.487 22.0707C127.622 22.9018 128.19 24.1485 128.19 25.8108V33.511Z"
          fill="#D12D34"
        />
        <path
          d="M134.348 29.4776C134.348 31.0259 135.294 31.7999 137.186 31.7999H140.64V27.1553H137.186C135.294 27.1553 134.348 27.9294 134.348 29.4776ZM140.64 25.4441C140.64 24.2708 140.327 23.407 139.702 22.8529C139.094 22.2988 138.296 22.0218 137.309 22.0218C136.569 22.0218 135.845 22.1359 135.138 22.364C134.43 22.5759 133.797 22.8692 133.238 23.2441L132.374 21.7773C132.736 21.5329 133.131 21.321 133.558 21.1418C134.003 20.9625 134.455 20.8158 134.916 20.7018C135.376 20.5714 135.828 20.4736 136.273 20.4084C136.733 20.3432 137.161 20.3106 137.556 20.3106C138.608 20.3106 139.538 20.4329 140.344 20.6773C141.15 20.9217 141.833 21.2721 142.392 21.7284C142.951 22.1847 143.37 22.7307 143.65 23.3663C143.946 23.9856 144.094 24.6782 144.094 25.4441V33.511H137.186C134.948 33.511 133.295 33.1769 132.226 32.5089C131.173 31.8407 130.647 30.8302 130.647 29.4776C130.647 28.125 131.173 27.1146 132.226 26.4464C133.295 25.7782 134.948 25.4441 137.186 25.4441H140.64Z"
          fill="#D12D34"
        />
        <path
          d="M150.868 33.511H147.414V16.3994H150.868V33.511Z"
          fill="#D12D34"
        />
        <path
          d="M43.6675 1.95445H48.2433C48.9344 1.95445 49.5309 2.01668 50.0335 2.14112C50.5448 2.25668 50.9666 2.42558 51.2986 2.64781C51.6305 2.86115 51.8773 3.11894 52.0386 3.42117C52.2002 3.7234 52.281 4.05675 52.281 4.4212C52.281 4.89232 52.1463 5.30125 51.8773 5.64792C51.6081 5.9946 51.2044 6.25238 50.6659 6.42127C51.3388 6.55461 51.8636 6.81684 52.2405 7.20797C52.6264 7.59019 52.8192 8.08356 52.8192 8.68802C52.8192 9.48804 52.4829 10.1236 51.8099 10.5947C51.146 11.057 50.0918 11.2881 48.647 11.2881H43.6675V1.95445ZM45.6189 7.02127V10.2881H48.647C49.32 10.2881 49.836 10.1547 50.1948 9.88806C50.5539 9.62138 50.7333 9.22135 50.7333 8.68802C50.7333 8.1191 50.5539 7.70131 50.1948 7.43462C49.836 7.15907 49.32 7.02127 48.647 7.02127H45.6189ZM48.2433 6.02124C49.5443 6.02124 50.1948 5.48791 50.1948 4.4212C50.1948 3.95009 50.0288 3.59006 49.6969 3.34118C49.374 3.08338 48.8895 2.95449 48.2433 2.95449H45.6189V6.02124H48.2433Z"
          fill="#A72328"
        />
        <path
          d="M59.7475 5.2879H58.1327C57.5225 5.2879 57.056 5.45237 56.7328 5.78124C56.4099 6.10127 56.2483 6.58129 56.2483 7.22129V11.2881H54.3643V7.22129C54.3643 6.31461 54.6513 5.61235 55.2256 5.11456C55.8087 4.60787 56.6433 4.35454 57.729 4.35454H59.7475V5.2879Z"
          fill="#A72328"
        />
        <path
          d="M64.18 10.6881C64.4673 10.6881 64.7362 10.6347 64.9877 10.5281C65.2388 10.4214 65.4588 10.257 65.6472 10.0347C65.8353 9.80359 65.9835 9.50581 66.0912 9.14136C66.2079 8.77691 66.2662 8.33689 66.2662 7.82132C66.2662 7.30573 66.2079 6.86574 66.0912 6.50126C65.9835 6.13681 65.8353 5.84348 65.6472 5.62124C65.4588 5.39011 65.2388 5.22122 64.9877 5.11455C64.7362 5.00788 64.4673 4.95454 64.18 4.95454C63.8929 4.95454 63.6237 5.00788 63.3725 5.11455C63.1214 5.22122 62.9015 5.39011 62.713 5.62124C62.5246 5.84348 62.372 6.13681 62.2556 6.50126C62.1477 6.86574 62.094 7.30573 62.094 7.82132C62.094 8.33689 62.1477 8.77691 62.2556 9.14136C62.372 9.50581 62.5246 9.80359 62.713 10.0347C62.9015 10.257 63.1214 10.4214 63.3725 10.5281C63.6237 10.6347 63.8929 10.6881 64.18 10.6881ZM64.18 4.08784C64.7812 4.08784 65.3331 4.17229 65.8353 4.34121C66.347 4.50119 66.7822 4.7412 67.141 5.06123C67.4998 5.37233 67.7781 5.76346 67.9756 6.2346C68.1818 6.69682 68.285 7.22574 68.285 7.82132C68.285 8.42578 68.1818 8.95912 67.9756 9.42136C67.7781 9.88361 67.4998 10.2747 67.141 10.5947C66.7822 10.9059 66.347 11.1414 65.8353 11.3014C65.3331 11.4703 64.7812 11.5548 64.18 11.5548C63.57 11.5548 63.0138 11.4703 62.5112 11.3014C62.0089 11.1414 61.578 10.9059 61.2192 10.5947C60.8605 10.2747 60.5778 9.88361 60.3713 9.42136C60.1738 8.95912 60.0752 8.42578 60.0752 7.82132C60.0752 7.22574 60.1738 6.69682 60.3713 6.2346C60.5778 5.76346 60.8605 5.37233 61.2192 5.06123C61.578 4.7412 62.0089 4.50119 62.5112 4.34121C63.0138 4.17229 63.57 4.08784 64.18 4.08784Z"
          fill="#A72328"
        />
        <path
          d="M73.1328 8.22132H71.7198V11.2881H69.8354V1.95445H71.7198V7.28796H73.1328L75.219 4.35453H77.2376L74.8824 7.68798L77.3723 11.2881H75.219L73.1328 8.22132Z"
          fill="#A72328"
        />
        <path
          d="M82.2187 10.6214C82.6583 10.6214 83.0664 10.5458 83.4433 10.3947C83.8292 10.2436 84.2061 10.0303 84.5739 9.75472L85.1124 10.5547C84.9417 10.7059 84.7399 10.8436 84.5065 10.9681C84.2822 11.0925 84.0354 11.1992 83.7665 11.2881C83.5063 11.3681 83.2327 11.4303 82.9453 11.4748C82.6583 11.5281 82.3713 11.5548 82.084 11.5548C81.4469 11.5548 80.8638 11.4703 80.3344 11.3014C79.814 11.1414 79.37 10.9059 79.0022 10.5947C78.634 10.2747 78.347 9.88361 78.1408 9.42136C77.9433 8.95912 77.8447 8.42578 77.8447 7.82132C77.8447 7.22574 77.9389 6.69682 78.1274 6.2346C78.3246 5.76346 78.5938 5.37233 78.9348 5.06123C79.2848 4.7412 79.6973 4.50119 80.1731 4.34121C80.6485 4.17229 81.1733 4.08784 81.7476 4.08784C82.2858 4.08784 82.7928 4.16787 83.2686 4.32785C83.744 4.48787 84.1568 4.72343 84.5065 5.03456C84.8565 5.33679 85.1345 5.70569 85.3411 6.14126C85.5473 6.57683 85.6506 7.07017 85.6506 7.62131V8.55467H79.9038C80.0114 9.29247 80.2673 9.82139 80.671 10.1414C81.0837 10.4614 81.5994 10.6214 82.2187 10.6214ZM81.7476 4.95454C81.4874 4.95454 81.245 5.00343 81.0207 5.10123C80.7964 5.199 80.5992 5.35456 80.4286 5.5679C80.2673 5.78123 80.1325 6.05682 80.0249 6.39459C79.9263 6.7324 79.8723 7.14129 79.8633 7.62131H83.6317C83.6317 7.15906 83.578 6.75904 83.4704 6.42126C83.3628 6.08349 83.2189 5.8079 83.0396 5.59457C82.8692 5.37233 82.6674 5.21235 82.434 5.11455C82.2097 5.00788 81.981 4.95454 81.7476 4.95454Z"
          fill="#A72328"
        />
        <path
          d="M92.5131 5.2879H90.8983C90.2881 5.2879 89.8217 5.45237 89.4985 5.78124C89.1756 6.10127 89.0139 6.58129 89.0139 7.22129V11.2881H87.1299V7.22129C87.1299 6.31461 87.4169 5.61235 87.9913 5.11456C88.5744 4.60787 89.4089 4.35454 90.4946 4.35454H92.5131V5.2879Z"
          fill="#A72328"
        />
      </g>
      <defs>
        <clipPath id="clip0_302_7893">
          <rect width="151" height="34" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default BtLogoSvg;
