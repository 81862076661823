import api from '@/services/api';
import { create } from 'zustand';

// Define the store interface
interface CategoryStore {
  listingCategory: any[]; // Type according to your expected data structure
  requirementCategory: any[];
  distressCategory: any[];
  fetchCategoryOption: () => Promise<void>;
}

// Create the store
const useCategoryStore = create<CategoryStore>((set) => ({
  listingCategory: [],
  requirementCategory: [],
  distressCategory: [],
  fetchCategoryOption: async () => {
    try {
      const response = await api.get('/agent/listings-categories'); // Replace with your API
      set({
        listingCategory: response.data?.listingCat,

        requirementCategory: response.data?.requiredCat,
        distressCategory: response.data?.distressCat,
      }); // Update state with fetched data
    } catch (error) {
      console.error('Failed to fetch category options:', error);
    }
  },
}));

export default useCategoryStore;
