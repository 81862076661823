import React, { createContext, useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';

interface MobileContextType {
  isMobile: boolean;
  setIsMobile: (isMobile: boolean) => void;
}

export const MobileContext = createContext<MobileContextType>({
  isMobile: false,
  setIsMobile: () => {},
});

interface MobileProviderProps {
  children: React.ReactNode;
}

export const MobileProvider: React.FC<MobileProviderProps> = ({ children }) => {
  const [isMobileState, setIsMobileState] = useState(false);

  useEffect(() => {
    setIsMobileState(isMobile);
  }, []);

  const setIsMobile = (newIsMobile: boolean) => {
    setIsMobileState(newIsMobile);
  };

  return (
    <MobileContext.Provider value={{ isMobile: isMobileState, setIsMobile }}>
      {children}
    </MobileContext.Provider>
  );
};
