import styles from '@/styles/Main/Layout/MainFooter.module.scss';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useState } from 'react';
interface locationProps {
  _id: string;
  thisMonthTxn: number;
  prevMonthTxn: number;
  yoyChange: number;
  users: {
    _id: string;
    firstName: string;
    lastName: string;
    phoneNumber: string;
    profilePic: string;
  };
}
const MainFooter = () => {
  const [limit, setlimit] = useState(0);
  const [openenq, setopenenq] = useState(false);

  const [mainDatas, setmainDatas] = useState([]);
  const [sequence, setsequence] = useState('Secondary');
  const sequences = ['Secondary', 'Primary'];
  // const { data: topLocations, isLoading } = useSWRImmutable(
  //   `/transaction/top-location-monthly?page=${limit}&sequence=${sequence}`,
  //   fetcher
  // );
  // useEffect(() => {
  //   //@ts-ignore
  //   if (topLocations) setmainDatas([...mainDatas, ...topLocations]);
  // }, [topLocations]);

  const router = useRouter();

  return (
    <div className={styles.container}>
      <div className={styles.mainFooterContainer}>
        {/* <h1>Yearly Location Trends in Dubai Real Estate</h1> */}

        {/* <div className={styles.mainFooterContainer_mid}>
          <div style={{ width: '100%' }}>
            <Box
              className={styles2.mainContainer_main_head}
              sx={{ width: '100%' }}
            >
              <div className={styles2.mainContainer_main_head_tabs}>
                {sequences.map((item, index) => (
                  <button
                    onClick={(e) => {
                      setmainDatas([]);
                      setlimit(0);
                      setsequence(item);
                    }}
                    key={item + String(index)}
                    className={
                      styles2[
                        sequence === item
                          ? 'mainContainer_main_head_tabs_selected'
                          : 'mainContainer_main_head_tabs_notselected'
                      ]
                    }
                  >
                    {item}
                  </button>
                ))}
              </div>
            </Box>
          </div>
          {mainDatas.length > 0 &&
            mainDatas.map((locationData: locationProps) => {
              const agentProfile = locationData.users || {
                firstName: 'Ali',
                lastName: 'Hassan',
                phoneNumber: '+971503683775',
                RealEstateName: 'Aaj Property',
                email: 'Alihassan@aajproperty.com',
                profilePic:
                  'https://brokerterminal.sgp1.cdn.digitaloceanspaces.com/5005061.webp',
              };
              return (
                <div
                  key={locationData._id}
                  className={styles.mainFooterContainer_mid_item}
                >
                  <div className={styles.mainFooterContainer_mid_item_avatar}>
                    {agentProfile ? (
                      <Image
                        src={agentProfile?.profilePic || '/'}
                        alt={agentProfile?.firstName[0]}
                        width={50}
                        height={50}
                      />
                    ) : (
                      <Image
                        placeholder={`data:image/svg+xml;base64,${ImageToBase64(
                          LoaderShimmer(700, 475)
                        )}`}
                        src={''}
                        alt={locationData._id[0]}
                        width={50}
                        height={50}
                      />
                    )}
                  </div>

                  {agentProfile && (
                    <div
                      className={styles.mainFooterContainer_mid_item_mess}
                      onClick={() =>
                        //                         window.open(
                        //                           `https://wa.me/${agentProfile?.phoneNumber
                        //                             .replaceAll('-', '')
                        //                             .replaceAll(' ', '')}?text=Hey ${
                        //                             agentProfile?.firstName
                        //                           }, I'm interested in the ${
                        //                             locationData._id
                        //                           } property insights on *Brokerterminal*. Can you please provide more information?

                        // https://brokerterminal.com/dubai/trends/${slugify(locationData._id)}?days=365
                        //                         `,
                        //                           '_self'
                        //                         )
                        setopenenq(true)
                      }
                    >
                      <MessageIconSvg color="#fff" width={20} height={20} />
                    </div>
                  )}
                  <TerminalEnquiry
                    rental={false}
                    open={openenq}
                    setOpen={setopenenq}
                    locations={locationData._id}
                    project={''}
                    source={'https://brokerterminal.com'}
                    bed={''}
                    user={locationData.users}
                  />
                  <span
                    className={styles.mainFooterContainer_mid_item_location}
                    onClick={() =>
                      router.push(
                        `/dubai/trends?keyword=${locationData._id}&days=365`
                      )
                    }
                  >
                    {locationData._id}{' '}
                    <span>({locationData.thisMonthTxn})</span>{' '}
                  </span>

                  {locationData.yoyChange * 100 &&
                  locationData.yoyChange * 100 != 0 ? (
                    <div
                      className={styles.mainFooterContainer_mid_item_stats}
                      onClick={() =>
                        router.push(
                          `/dubai/trends?keyword${locationData._id}&days=365`
                        )
                      }
                      style={{
                        backgroundColor:
                          locationData.yoyChange * 100 < 0
                            ? 'var(--light-red)'
                            : 'var(--light-green)',
                      }}
                    >
                      {locationData.yoyChange * 100 > 0 ? (
                        <ArrowDropUp style={{ color: 'var(--stock-green)' }} />
                      ) : (
                        <ArrowDropDown style={{ color: 'var(--primary)' }} />
                      )}
                      <span
                        style={{
                          color:
                            locationData.yoyChange * 100 > 0
                              ? 'var(--card-main)'
                              : 'var(--card-main)',
                        }}
                      >
                        {' '}
                        {(locationData.yoyChange * 100).toFixed(0)}% Txn
                      </span>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              );
            })}
          {(!topLocations || isLoading) && <DotLoader />}
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <button onClick={() => setlimit(limit + 1)}>
              More Locations{'+'}
            </button>
          </div>
        </div> */}
        <div className={styles.mainFooterContainer_top}>
          <p>Delivering Excellence in Dubai's Real Estate</p>
          {/* <span>
            <div>
              <span>©</span>2024 Terminal web Publishing LLC
            </div>
            <Link
              href={'/legal-disclaimer'}
              style={{ color: '#fff', textDecoration: 'underline' }}
            >
              Disclaimer
            </Link>
            <br />

            
            <br />
            <Link
              href={'/group-disclaimer'}
              style={{ color: '#fff', textDecoration: 'underline' }}
            >
              Group Disclaimer
            </Link>
          </span> */}
          <div className={styles.mainFooterContainer_top_divider}></div>
          <span>
            <Link
              href={'/legal-disclaimer'}
              style={{ color: '#fff', textDecoration: 'underline' }}
            >
              Disclaimer
            </Link>
          </span>
          <div className={styles.mainFooterContainer_top_divider}></div>
          <span>
            <Link
              href={'/group-disclaimer'}
              style={{ color: '#fff', textDecoration: 'underline' }}
            >
              Group Disclaimer
            </Link>
          </span>
        </div>
        <div className={styles.mainFooterContainer_bot}>
          <Image
            src={
              'https://brokerterminal.sgp1.cdn.digitaloceanspaces.com/btlogo/bt-logo-bw.png'
            }
            alt="Brokerterminal"
            width={100}
            height={30}
          />
          <span>
            Broker Terminal<span style={{ fontSize: '14px' }}>©</span> 2024
          </span>
        </div>
      </div>
    </div>
  );
};

export default MainFooter;
