const ErrorIconSvg = ({ color = '#d12d34', width = 24, height = 24 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="type=error">
        <g id="ph:x">
          <path
            id="Vector"
            d="M15.8828 15.3168C15.9199 15.354 15.9494 15.3981 15.9695 15.4466C15.9896 15.4952 16 15.5472 16 15.5998C16 15.6524 15.9896 15.7044 15.9695 15.7529C15.9494 15.8015 15.9199 15.8456 15.8828 15.8828C15.8456 15.9199 15.8015 15.9494 15.7529 15.9695C15.7044 15.9896 15.6524 16 15.5998 16C15.5472 16 15.4952 15.9896 15.4466 15.9695C15.3981 15.9494 15.354 15.9199 15.3168 15.8828L12 12.5655L8.68318 15.8828C8.60813 15.9578 8.50634 16 8.4002 16C8.29406 16 8.19227 15.9578 8.11722 15.8828C8.04216 15.8077 8 15.7059 8 15.5998C8 15.4937 8.04216 15.3919 8.11722 15.3168L11.4345 12L8.11722 8.68318C8.04216 8.60813 8 8.50634 8 8.4002C8 8.29406 8.04216 8.19227 8.11722 8.11722C8.19227 8.04216 8.29406 8 8.4002 8C8.50634 8 8.60813 8.04216 8.68318 8.11722L12 11.4345L15.3168 8.11722C15.3919 8.04216 15.4937 8 15.5998 8C15.7059 8 15.8077 8.04216 15.8828 8.11722C15.9578 8.19227 16 8.29406 16 8.4002C16 8.50634 15.9578 8.60813 15.8828 8.68318L12.5655 12L15.8828 15.3168Z"
            fill={color}
          />
        </g>
        <rect x="0.5" y="0.5" width="23" height="23" rx="11.5" stroke={color} />
      </g>
    </svg>
  );
};

export default ErrorIconSvg;
