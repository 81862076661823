const WarningIconSvg = ({ color = '#F19F00', width = 24, height = 24 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="type=warning">
        <g id="ci:warning">
          <path
            id="Vector"
            d="M12.0049 9V13.5336M12.0332 15.8005V15.8571H11.9766V15.8005H12.0332Z"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <rect x="0.5" y="0.5" width="23" height="23" rx="11.5" stroke={color} />
      </g>
    </svg>
  );
};

export default WarningIconSvg;
