const SuccessIconSvg = ({ color = '#23946B', width = 24, height = 24 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="type=success">
        <g id="check">
          <path
            id="Vector"
            d="M9 12.0218L11.1432 14.1646L15.4286 9.87891"
            stroke={color}
            strokeLinecap="round"
            stroke-Linejoin="round"
          />
        </g>
        <rect x="0.5" y="0.5" width="23" height="23" rx="11.5" stroke={color} />
      </g>
    </svg>
  );
};

export default SuccessIconSvg;
